import React from "react";
import { Container, CenterWrapper } from "app/components/RouteComponents";
import moment from "moment";
import { ReactSVG } from "react-svg";
import { H2, H5, P2 } from "app/components/Typography";
import Div from "app/components/Div";
import { DownloadAppSection } from "app/components/DownloadAppSection";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useCalculateClassCompletedBadges, useUserAchievements } from "./hooks";
import styles from "./styles.module.scss";

interface AchievementsProps {
  username: string;
}

interface Badge {
  name: string;
  activeImageUrl?: string;
  challenge?: {
    title: string;
  };
}

interface UserBadge {
  earnedAt: string;
  badge: Badge | null;
}

interface YearGroup {
  year: number;
  userBadges: UserBadge[];
}

export function Achievements({ username }: AchievementsProps): JSX.Element {
  const { badgesByYear, loading, error, refetch } = useUserAchievements({
    username,
  });

  useCalculateClassCompletedBadges();

  if (loading) {
    return <LoaderCentered />;
  }

  if (error) {
    return (
      <CenterWrapper>
        <Container>
          <Div>Error loading achievements. Click to retry.</Div>
          <button
            type="button"
            className={styles.retryButton}
            onClick={() => refetch()}
          >
            Retry
          </button>
        </Container>
      </CenterWrapper>
    );
  }

  if (badgesByYear.length === 0) {
    return (
      <CenterWrapper>
        <Container>
          <Div mt={3} className={styles.downloadSection}>
            <DownloadAppSection title="Start earning achievements by downloading our app and completing challenges!" />
          </Div>
        </Container>
      </CenterWrapper>
    );
  }

  return (
    <>
      {badgesByYear.map((yearGroup: YearGroup) => (
        <Div key={yearGroup.year} className={styles.yearSection}>
          <H2 mb={3}>{yearGroup.year}</H2>
          <Div className={styles.achievementWrapper}>
            {yearGroup.userBadges.map((item: UserBadge) => {
              if (!item.badge) {
                return null;
              }

              return (
                <Div
                  key={`${item.badge.name}-${item.earnedAt}`}
                  className={styles.achievementContainer}
                >
                  {item.badge.activeImageUrl ? (
                    <ReactSVG
                      src={item.badge.activeImageUrl}
                      className={styles.badgeImage}
                    />
                  ) : (
                    <Div className={styles.badgeImagePlaceholder} />
                  )}
                  <H5 textAlign="center">
                    {item.badge.challenge
                      ? item.badge.challenge.title
                      : item.badge.name}
                  </H5>
                  <P2 className={styles.badgeDate}>
                    {moment(item.earnedAt).format("MMM D, YYYY")}
                  </P2>
                </Div>
              );
            })}
          </Div>
        </Div>
      ))}
    </>
  );
}
